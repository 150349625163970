import styles from "./Brand.module.css";
export const Brand = () => {
    return (
        <div className={styles.brandContainer}>
            <div className={styles.brandContent}>
                <div className={styles.brandTitle}>
                    {" "}
                    <div className={styles.mainTitle}>
                        <h1>Компания «Цзинь Тай»</h1>
                    </div>
                </div>
                <img src="./assets/china.jpg" alt="" className={styles.img} />
                <div>
                    <h2 className={styles.brandSubtitle}>Поставщик из КНР</h2>
                </div>
                <span className={styles.text}>
                    <p>
                        Компания находится в городе Благовещенске. Основным видом деятельности
                        является продажа грузовых автомобилей, малотоннажных грузовиков,
                        спецтехники и разных видов прицепов (бортовые полуприцепы, цистерны,
                        лесовозы). На протяжении нескольких лет мы успешно поставляем на
                        рынок России различные виды специальной и автомобильной техники
                        производства ведущих заводов Китая.
                    </p>
                    <div>
                        Мы сотрудничаем с производителями, продукция которых имеет
                        международные сертификаты соответствия и хорошо зарекомендовала себя
                        в работе. Китайскую спецтехнику отличает:{" "}
                        <ul>
                            <li>-наилучшее соотношение цены и качества</li>
                            <li>-надежность</li>
                            <li>-неприхотливость в эксплуатации</li>
                            <li>-простота в обслуживании</li>
                        </ul>{" "}
                        <p> Спецтехника специально адаптирована к российским условиям:</p>
                    </div>
                    <ul>
                        <li> -высокоэкономичный расход топлива</li>{" "}
                        <li> -утеплённая кабина </li>
                        <li> -усиленные подвеска, мосты и рама.</li>
                    </ul>{" "}
                    <p>
                        {" "}
                        В компании имеется собственная команда технических специалистов, которые каждый год проходят обучение по
                        повышению квалификации.
                        Опыт компании позволяет завозить не только
                        стандартную технику, но и работать с заводами по индивидуальным
                        заказам.
                    </p>
                    <p>
                        Собственный склад запчастей, своевременно пополняющийся
                        ассортиментом, отдел
                        послепродажного и гарантийного обслуживания гарантируют качество поставляемой продукции. Менеджеры компании применяют идивидуальный подход к каждому клиенту, в зависимости от пожеланий подберут технику нужной комплектации.
                        {" "}
                    </p>
                    <div className={styles.numberContainer}>
                        <div className={styles.number}>
                            <b>Звоните прямо сейчас! 89140647833</b>
                        </div>
                    </div>
                </span>
            </div>
        </div>
    );
};
