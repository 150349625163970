import { Brand } from "../../component/Brand/Brand";
import { Whatsapp } from "../../component/whatsaap/Whatsapp";
export const AboutBrand = () => {
    return (
        <div>
            <Brand></Brand>
            <Whatsapp></Whatsapp>
        </div>
    );
};
