import styles from "./Politic.module.css";
export const Politic = () => {
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Политика конфиденциальности</h1>
            <div className={styles.content}>
                <p>
                    Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных
                    данных» от 27.07.2006 года Вы подтверждаете свое согласие на обработку
                    ООО «Цзинь Тай» персональных данных: сбор, систематизацию, накопление,
                    хранение, уточнение (обновление, изменение), использование, передачу
                    исключительно в целях продажи программного обеспечения на Ваше имя, как
                    это описано ниже, блокирование, обезличивание, уничтожени
                </p>
                <p>
                    ООО «Цзинь Тай» гарантирует конфиденциальность получаемой информации.
                    Обработка персональных данных осуществляется в целях эффективного
                    исполнения заказов, договоров и иных обязательств, принятых ООО «Цзинь Тай» в качестве обязательных к исполнению.
                </p>
                <p>
                    В случае необходимости предоставления Ваших персональных данных
                    правообладателю, дистрибьютору или реселлеру программного обеспечения в
                    целях регистрации программного обеспечения на ваше имя, вы даёте
                    согласие на передачу ваших персональных данных. ООО «Цзинь Тай»
                    гарантирует, что правообладатель, дистрибьютор или реселлер программного
                    обеспечения осуществляет защиту персональных данных на условиях,
                    аналогичных изложенным в Политике конфиденциальности персональных данных
                </p>
                <p>
                    Настоящее согласие распространяется на следующие Ваши персональные
                    данные: фамилия, имя и отчество, адрес электронной почты, почтовый адрес
                    доставки заказов, контактный телефон, платёжные реквизиты.
                </p>
                <p>
                    Срок действия согласия является неограниченным. Вы можете в любой момент
                    отозвать настоящее согласие, направив письменное уведомления на адрес:
                    ООО «Цзинь Тай» с пометкой «Отзыв согласия на обработку персональных
                    данных».
                </p>
                <p>
                    Обращаем ваше внимание, что отзыв согласия на обработку персональных
                    данных влечёт за собой удаление Вашей учётной записи с Интернет-сайта (
                    https://jintai-tech.ru/ ), а также уничтожение записей,
                    содержащих ваши персональные данные, в системах обработки персональных
                    данных ООО «Цзинь Тай», что может сделать невозможным пользование
                    интернет-сервисами компании ООО «Цзинь Тай».
                </p>
                <p>
                    Гарантирую, что представленная мной информация является полной, точной и
                    достоверной, а также что при представлении информации не нарушаются
                    действующее законодательство Российской Федерации, законные права и
                    интересы третьих лиц. Вся представленная информация заполнена мною в
                    отношении себя лично.
                </p>
                <p>
                    Настоящее согласие действует в течение всего периода хранения
                    персональных данных, если иное не предусмотрено законодательством
                    Российской Федерации.
                </p>
            </div>
        </div>
    );
};
